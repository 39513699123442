<template>
  <crud-form
    :schema="schema"
    :model-id="id"
    :path="'translation'"
    :form-title="$t('editTranslation')"
    :redirect-route="'TranslationList'"
  />
</template>

<script>
  import CrudForm from "../../components/CrudForm";
  import { schema } from './schema/translation'

export default {
  name: 'UpdateTranslation',
  components: {
    CrudForm
  },
  props: ['id'],
  data: () => {
    return {
      schema,
    }
  },
}
</script>
